import React from 'react'
import MUIDialog from '@material-ui/core/Dialog'
import MUIDialogContent from '@material-ui/core/DialogContent'
import styled from 'styled-components'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { Check, ChevronLeft, KeyboardArrowDown } from '@mui/icons-material'
import theme from 'styles/theme'
import useStartWorkoutDialog from 'activity/hooks/useStartWorkoutDialog'
import {
  momentumLogo,
  restartTimerIcon,
  resumeTimerIcon,
  runnerIcon,
  startTimerIcon,
  stopTimerIcon
} from 'constants/assets'
import parseVideoUrl from 'services/parseVideoUrl'
import formatTime from 'services/formatTime'

import Exercises from './Exercises'
import EndWorkoutDialog from './EndWorkoutDialog'

export default function StartWorkoutDialog() {
  const {
    state,
    closeStartWorkoutDialog,
    startWorkoutDialog,
    data,
    timerStarted,
    hours,
    minutes,
    seconds,
    startTimer,
    start,
    pause,
    reset,
    isRunning,
    exerciseNumber,
    groupName,
    blockName,
    closeDialogOrGoToPreviousExercise,
    skipCurrentExercise,
    currentExercise,
    markCurrentExerciseAsDone
  } = useStartWorkoutDialog()

  const videoUrl = data?.exercises?.[exerciseNumber].video

  const currentExerciseStatus =
    state?.programDialog?.exerciseStatuses?.[currentExercise?.id]

  return (
    <MUIDialog.Stylesheet
      fullScreen
      open={startWorkoutDialog.open}
      onClose={closeStartWorkoutDialog}
      disableEnforceFocus
    >
      <Toolbar className="toolbar">
        <IconButton
          edge="start"
          color="inherit"
          onClick={closeDialogOrGoToPreviousExercise}
        >
          <ChevronLeft sx={{ color: theme.palette.secondary.g800 }} />
        </IconButton>
        <StartWorkoutDialog.Title>{data?.programName}</StartWorkoutDialog.Title>
      </Toolbar>

      <StartWorkoutDialog.Content>
        <div className="container">
          {videoUrl ? (
            <div className="video">
              <iframe
                src={parseVideoUrl(videoUrl)}
                title="Exercise Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </div>
          ) : (
            <div className="noVideo">
              <img src={momentumLogo} alt="Momentum Logo" />
            </div>
          )}

          <div className="header">
            <div className="currentExercise">
              <p>{data?.programName}</p>
              <div className="currentExerciseName">
                <h3>{currentExercise?.name}</h3>
                {currentExerciseStatus?.done ? (
                  <div className="done">
                    <Check />
                    <span>Done</span>
                  </div>
                ) : (
                  <button type="button" onClick={markCurrentExerciseAsDone}>
                    Mark as done
                  </button>
                )}
              </div>
            </div>
            <div className="exerciseNumber">
              <p>Exercise</p>
              <h3>{`${exerciseNumber + 1}/${data?.exercises?.length}`}</h3>
            </div>
          </div>

          <div className="timerAndSkip">
            <div className="timerBarContainer">
              {timerStarted ? (
                <div className="timerBar">
                  <span className="time">
                    {formatTime(hours, minutes, seconds)}
                  </span>
                  <button type="button" onClick={isRunning ? pause : start}>
                    <img
                      src={isRunning ? stopTimerIcon : resumeTimerIcon}
                      alt="Stop/Resume Timer Icon"
                    />
                  </button>
                  <button type="button" onClick={reset}>
                    <img src={restartTimerIcon} alt="Restart Timer Icon" />
                  </button>
                </div>
              ) : (
                <button className="timerBar" type="button" onClick={startTimer}>
                  <img src={startTimerIcon} alt="Start Timer Icon" />
                  <span className="label">Start Timer</span>
                </button>
              )}
            </div>

            {data?.exercises?.length > exerciseNumber + 1 && (
              <div className="buttonContainer">
                <button
                  type="button"
                  className="nextTrainingBtn"
                  onClick={skipCurrentExercise}
                >
                  <img src={runnerIcon} alt="Runner Icon" />
                  <span>Next</span>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="exercisesControl">
          {data?.exercises?.length > exerciseNumber + 1 && (
            <div className="upNext">
              <div className="label">
                <p>Up Next</p>
                <KeyboardArrowDown sx={{ color: '#1B1B1B' }} />
              </div>

              <Exercises
                workoutMode
                currentExerciseNumber={exerciseNumber}
                currentGroupName={groupName}
                currentBlockName={blockName}
              />
            </div>
          )}
        </div>

        <EndWorkoutDialog />
      </StartWorkoutDialog.Content>
    </MUIDialog.Stylesheet>
  )
}

MUIDialog.Stylesheet = styled(MUIDialog)`
  padding-bottom: env(safe-area-inset-bottom);

  .toolbar {
    text-align: center;
    h3 {
      margin: 0;
      margin-right: 2rem;
      font-weight: 700;
    }
  }

  .btnContainer {
    text-align: center;
    margin-top: 2rem;
    padding: 1rem;
    .startNowBtn {
      border: 0;
      border-radius: 0.625rem;
      width: 100%;
      padding: 1rem;
      text-transform: uppercase;
      color: #fff;
      font-weight: 700;
      font-size: 0.875rem;
      background-color: #09555d;
      letter-spacing: 0.05rem;
    }
  }
`

StartWorkoutDialog.Title = styled.h3`
  color: ${theme.palette.secondary.g800};
  flex: 1;
  margin-bottom: 0;
`
StartWorkoutDialog.Content = styled(MUIDialogContent)`
  padding: 0 !important;

  .video {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      padding: 0 1rem 0 1rem;

      @media screen and (max-width: 765px) {
        border-radius: 0;
        padding: 0;
      }
    }

    @media screen and (max-width: 765px) {
      height: 33.5625rem;
      border-radius: 0;
    }
  }

  .noVideo {
    max-width: 30rem;
    padding: 0 1rem 0 1rem;
    margin: 0 auto;
    img {
      padding: 5rem;
      width: 100%;
    }
  }

  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;

  @media screen and (max-width: 765px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .container {
    padding: 0 !important;
    max-width: none;

    .header {
      margin-top: 2rem;
      padding: 0 1rem 0 1rem !important;
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      color: #3a4750;
      p {
        margin: 0;
      }
      .currentExercise {
        h3 {
          font-weight: 600;
          font-size: 1.2rem;
          margin: 0;
          margin-right: 0.5rem;
        }

        .currentExerciseName {
          display: flex;

          h3 {
            max-width: 11rem;
          }

          img {
            cursor: pointer;
          }

          button {
            width: 7rem;
            margin-left: 1rem;
            border: 0;
            border-radius: 1.875rem;
            background-color ${theme.palette.secondary.g800};
            color: #fff;
            padding: 0 0.5rem 0 0.5rem;
            font-weight: 600;
            font-size: 0.75rem;
            height: 2rem;

            @media screen and (max-width: 390px) {
              width: 12rem;
            }
          }

          .done {
            margin-left: 1rem;
            background-color: #198F96;
            border-radius: 1.875rem;
            color: #fff;
            padding: 0 0.5rem 0 0.5rem;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 0.75rem;
            height: 2rem;

            span {
              padding: 0 0.5rem 0 0.5rem;
            }
          }
        }
      }
      .exerciseNumber {
        text-align: center;
        h3 {
          font-weight: 700;
          font-size: 1.5rem;
          font-family: 'Open Sans', sans-serif;
          color: #3a4750;
        }
      }
    }

    .timerAndSkip {
      padding: 0 1rem 0 1rem !important;
      display: flex;
      margin-bottom: 2rem;
      .timerBarContainer {
        .timerBar {
          margin-right: 1rem;
          border: 0;
          padding: 1rem;
          border-radius: 0.625rem;
          color: ${theme.palette.secondary.g800};
          display: flex;
          align-items: center;
          justify-content: center;
          width: 14rem;
          font-weight: 600;
          background-color: #f8f8f8;

          button {
            border: 0;
            margin: 0;
            padding: 0;
            background-color: #f8f8f8;
          }

          span {
            height: 100%;
            padding: 0.3rem 0 0.3rem 0;
          }

          .label {
            margin-left: 0.5rem;
          }

          .time {
            font-weight: 700;
            font-size: 1.75rem;
            margin-right: 1.5rem;
            width: 6ch;
            font-family: 'Open Sans', sans-serif;
          }

          img {
            margin-right: 0.5rem;
          }
        }
      }

      .buttonContainer {
        width: 100%;

        .nextTrainingBtn {
          height: 100%;
          border: 0;
          background-color: ${theme.palette.secondary.g800};
          color: #fff;
          border-radius: 0.625rem;
          padding: 1rem;
          font-weight: 700;
          width: 100%;

          span {
            margin-left: 0.5rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .upNext {
    text-align: center;

    .label {
      display: flex;
      justify-content: center;
      margin-bottom: -2rem;
    }

    p {
      font-weight: 600;
      color: #191919;
    }
  }

  .getReady {
    text-align: center;
    padding-top: 14rem;
    h2 {
      color: ${theme.palette.secondary.g800};
      font-size: 2rem;
    }

    .timer {
      div {
        text-align: center;
        margin: 0 auto;
      }
      p {
        font-size: 6.5625rem;
        font-weight: 600;
        color: ${theme.palette.secondary.g800};
      }
    }
  }
`
