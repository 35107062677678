import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'
import { UserContext } from 'account'
import { LayoutContext } from 'common/ducks'
import styled from 'styled-components'
import useApi from 'common/hooks/useApi'
import useAppTitle from 'common/hooks/useAppTitle'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import HelpIcon from '@material-ui/icons/Help'
import DialogContent from '@material-ui/core/DialogContent'
import { ReactComponent as Logo } from './fitbit-logo.svg'
import { ReactComponent as AppleLogo } from './apple-logo.svg'

function UploadsChart() {
  const user = useContext(UserContext)
  const { setAppLoading } = useContext(LayoutContext)
  const [open, setOpen] = useState(false)

  const lastSynced = user?.fitbitLastSyncedAt
    ? moment(user.fitbitLastSyncedAt).fromNow()
    : 'No Data'

  useAppTitle('Sync')

  const [{ data }] = useApi({ url: 'fitbit/' })
  const fitbitUrl = data?.fitbitAuthorizationUrl

  const [dataApple] = useApi({ url: 'apple/' })

  const appleLastSynced = dataApple.data?.applelastsynced
    ? moment(dataApple.data?.applelastsynced).fromNow()
    : 'No Data'

  const [{ loading: createLoading }, upload] = useApi(
    {
      method: 'POST',
      url: 'apple/upload_apple/',
    },
    { manual: true }
  )

  useEffect(() => {
    setAppLoading(!data || !dataApple || createLoading)
  }, [data, dataApple, createLoading, setAppLoading])

  const onFileChange = (event) => {
    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('file', file, 'export.xml')
    upload({
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    })
  }

  const handleOpen = (state) => () => setOpen(state)

  return (
    <UploadsChart.Stylesheet>
      <div className="header-large-title">
        <h1 className="title">Sync</h1>
      </div>

      <div className="section mt-2">
        <div className="card">
          <div className="card-body">
            <UploadsChart.Logo />
            <br />
            {`Last synced: ${lastSynced}`}
            <br />
            <UploadsChart.Button
              variant="contained"
              color="primary"
              href={fitbitUrl}
              style={{ marginTop: '5%' }}
            >
              Sync
            </UploadsChart.Button>
          </div>
        </div>
      </div>

      <div className="section mt-2">
        <div className="card">
          <div className="card-body">
            <UploadsChart.AppleLogo />
            <UploadsChart.HelpIcon
              variant="outlined"
              color="primary"
              onClick={handleOpen(true)}
            />
            <Dialog onClose={handleOpen(false)} open={open}>
              <DialogContent>
                <UploadsChart.HelpTitle variant="h6">
                  Exporting Apple Health Data
                </UploadsChart.HelpTitle>
                <UploadsChart.HelpContent>
                  <li>
                    Open the Apple <b>Health</b> app on your iPhone
                  </li>
                  <li>Tap on your profile image in the upper-right corner</li>
                  <li>
                    Scroll to the bottom and tap <i>Export All Health Data</i>
                  </li>
                  <li>
                    <b>Save to Files </b>
                    in a location you can easily access from your computer
                  </li>
                  <li>On your computer, download the exported .zip file</li>
                  <li>Go to the .zip file and unzip it</li>
                  <li>
                    Find the <b>export.xml</b> file inside the{' '}
                    <i>apple_health_export</i> folder and upload it to Sidekick
                  </li>
                </UploadsChart.HelpContent>
              </DialogContent>
            </Dialog>
            <br />
            {`Last synced:  ${appleLastSynced}`}
            <br />
            <label htmlFor="apple-upload" style={{ width: '100%' }}>
              <input
                accept="xml"
                type="file"
                id="apple-upload"
                style={{ display: 'none' }}
                onChange={onFileChange}
              />
              <UploadsChart.Button
                variant="contained"
                color="primary"
                component="span"
                style={{ marginTop: '5%' }}
              >
                Upload
              </UploadsChart.Button>
            </label>
          </div>
        </div>
      </div>
    </UploadsChart.Stylesheet>
  )
}

UploadsChart.Stylesheet = styled.div``
UploadsChart.Button = styled(Button)`
  margin-top: 5%;
  width: 100%;
`
UploadsChart.Logo = styled(Logo)`
  height: 25%;
  width: 30%;
  margin-bottom: 5%;
`
UploadsChart.AppleLogo = styled(AppleLogo)`
  height: 25%;
  width: 30%;
  margin-bottom: 5%;
`
UploadsChart.HelpIcon = styled(HelpIcon)`
  margin-left: 62%;
  margin-top: -16%;
`
UploadsChart.HelpTitle = styled(Typography)`
  margin-bottom: 1em !important;
  text-align: center;
`
UploadsChart.HelpContent = styled.ol`
  padding-inline-start: 14px;

  li {
    margin-bottom: 15px;
    padding-left: 10px;
  }
`

export default UploadsChart
