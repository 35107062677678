export default function parseVideoUrl(url) {
  if (url.includes('youtube')) {
    const embedUrl = url
      .replace('watch?v=', 'embed/')
      .replace('shorts', 'embed')

    if (!embedUrl.includes('autoplay=1')) {
      return `${embedUrl}?autoplay=1`
    }
  } else if (url.includes('google')) {
    if (!url.includes('preview')) {
      return `${url}/preview`
    }
    return url
  }

  return url
}
