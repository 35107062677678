import React from 'react'
import MUIDialog from '@material-ui/core/Dialog'
import MUIDialogContent from '@material-ui/core/DialogContent'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import theme from 'styles/theme'
import useProgramDialog from 'activity/hooks/useProgramDialog'
import Switch from '@mui/material/Switch'
import { Stack } from '@mui/material'
import { styled as muiStyled } from '@mui/material/styles'
import { momentumLogo, saveEntriesIcon, startNowIcon } from 'constants/assets'
import { Check, ChevronLeft } from '@mui/icons-material'
import CustomModal from 'common/components/CustomModal'
import { openStartWorkoutDialogAction } from 'activity/ducks'

import Exercises from './Exercises'
import StartWorkoutDialog from './StartWorkoutDialog'
import ProgramDoneDialog from './ProgramDoneDialog'

const AntSwitch = muiStyled(Switch)(({ theme: switchTheme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          switchTheme.palette.mode === 'dark' ? '#09555D' : '#09555D'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: switchTheme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      switchTheme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box'
  }
}))

export default function ProgramDialog() {
  const {
    closeProgramDialog,
    state,
    logAllWorkoutsForDateSelected,
    openConfirmationModal,
    closeConfirmationModal,
    checked,
    dispatch
  } = useProgramDialog()
  const { data } = state.programDialog

  const modalActionComponents = [
    { name: 'Confirm', onClick: logAllWorkoutsForDateSelected },
    {
      name: 'Cancel',
      onClick: closeConfirmationModal,
      backgroundColor: '#DFEBEC',
      color: theme.palette.secondary.g800
    }
  ]

  return (
    <MUIDialog.Stylesheet
      fullScreen
      open={state.programDialog.ui.open}
      onClose={closeProgramDialog}
      disableEnforceFocus
    >
      <Toolbar className="toolbar">
        <IconButton edge="start" color="inherit" onClick={closeProgramDialog}>
          <ChevronLeft sx={{ color: theme.palette.secondary.g800 }} />
        </IconButton>
        <ProgramDialog.Title>{data.programName}</ProgramDialog.Title>
      </Toolbar>

      <ProgramDialog.Content>
        <div className="container">
          <div className="programThumbnail">
            <img
              src={data.exercises?.[0]?.thumbnail || momentumLogo}
              alt="Program Thumbnail"
            />
          </div>

          <div className="startWorkout">
            <button
              className="startWorkoutBtn"
              type="button"
              onClick={() => dispatch(openStartWorkoutDialogAction())}
            >
              Start now <img src={startNowIcon} alt="Start Now Icon" />
            </button>
          </div>

          <div className="programDetails">
            <div className="description">
              <h4>
                {data.date?.year}-{data.date?.month ? data.date.month + 1 : 0}-
                {data.date?.date}
              </h4>
              <h2>{data.programName}</h2>
              <p>{data.programDescription}</p>
            </div>
          </div>
        </div>

        <div>
          <div className="exercisesControl">
            <div className="header">
              <h3>Exercises</h3>
              <Stack direction="row" spacing={1} alignItems="center">
                {checked ? (
                  <div className="allLogged">
                    <Check sx={{ color: '#fff' }} />
                    <span>Done</span>
                  </div>
                ) : (
                  <>
                    <AntSwitch
                      checked={checked}
                      inputProps={{ 'aria-label': 'ant design' }}
                      onClick={openConfirmationModal}
                    />

                    <span>Mark all as done</span>
                  </>
                )}
              </Stack>
            </div>
          </div>

          <Exercises checked={checked} showMarkAsDone />
        </div>
      </ProgramDialog.Content>

      <div className="btnContainer">
        <button
          type="button"
          className="startNowBtn"
          onClick={() => dispatch(openStartWorkoutDialogAction())}
        >
          Start now
        </button>
      </div>

      <CustomModal
        title="Save your entries?"
        icon={saveEntriesIcon}
        actionComponents={modalActionComponents}
        state={{
          open: state.programDialog.confirmationModal.open,
          closeModal: closeConfirmationModal
        }}
      />

      <StartWorkoutDialog />
      <ProgramDoneDialog />
    </MUIDialog.Stylesheet>
  )
}

ProgramDialog.Content = styled(MUIDialogContent)`
  padding: 0 !important;

  .programThumbnail {
    img {
      width: 100%;
      max-height: 35rem;
      border-radius: 1.875rem;
    }
    @media screen and (max-width: 765px) {
      img {
        border-radius: 0;
      }
    }
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media screen and (max-width: 765px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .container {
    max-width: none;

    padding: 0 1rem 0 1rem !important;
    @media screen and (max-width: 765px) {
      padding: 0 !important;
    }

    .programDetails {
      @media screen and (max-width: 765px) {
        padding: 0 1rem 0 1rem !important;
      }
      margin-top: -2rem;
      h4 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 0.875rem;
        color: #191919;
      }

      h2 {
        font-weight: 700;
        font-size: 1.25rem;
      }

      p {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 0.875rem;
      }
    }

    .startWorkout {
      text-align: right;
      margin-right: 2rem;

      .startWorkoutBtn {
        position: relative;
        bottom: 1.8rem;
        text-align: right;
        border: 0;
        background-color: ${theme.palette.secondary.g800};
        color: #fff;
        padding: 1rem 2rem 1rem 2rem;
        text-transform: uppercase;
        font-weight: 700;
        border-radius: 0.625rem;
        align-items: center;
        img {
          margin-top: -0.2rem;
        }
      }
    }
  }

  .exercisesControl {
    padding: 0 1rem 0 1rem;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-weight: 700;
        font-size: 1rem;
        margin: 0;
      }
      span {
        font-weight: 600;
        font-size: 0.875rem;
      }

      .allLogged {
        background-color: #18bba9;
        color: #fff;
        padding: 0.25rem 0.75rem 0.25rem 0.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 6rem;
        border-radius: 0.3125rem;
      }
    }
  }
`
MUIDialog.Stylesheet = styled(MUIDialog)`
  padding-bottom: env(safe-area-inset-bottom);

  .toolbar {
    text-align: center;
    h3 {
      margin: 0;
      margin-right: 2rem;
      font-weight: 700;
    }
  }

  .btnContainer {
    text-align: center;
    margin-top: 2rem;
    padding: 1rem;
    .startNowBtn {
      border: 0;
      border-radius: 0.625rem;
      width: 100%;
      padding: 1rem;
      text-transform: uppercase;
      color: #fff;
      font-weight: 700;
      font-size: 0.875rem;
      background-color: #09555d;
      letter-spacing: 0.05rem;
    }
  }
`
ProgramDialog.Title = styled.h3`
  color: ${theme.palette.secondary.g800};
  flex: 1;
  margin-bottom: 0;
  margin-left: ${theme.spacing(2)}px;
`
