/* eslint-disable */
import React, { useContext, useEffect, useCallback, useState } from 'react'
import { LayoutContext } from 'common/ducks'
import { PieChart, Pie, Cell, Label, ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Bar, BarChart } from 'recharts';
import styled from 'styled-components'
import useApi from 'common/hooks/useApi'
import useAppTitle from 'common/hooks/useAppTitle'
import Grid from '@material-ui/core/Grid'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import CalHeatmap from 'cal-heatmap'
import 'cal-heatmap/cal-heatmap.css'
import { UserContext } from 'account'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import PageTitle from 'common/components/PageTitle'

function Athletes() {

    const thresholds = [
        {
            "name": "Ready",
            "value": 100
        },
        {
            "name": "Moderate Risk",
            "value": 70
        },
        {
            "name": "At Risk",
            "value": 50
        },
    ];

    const startDate = new Date()
    startDate.setDate(startDate.getDate()-180)
    const { setAppLoading, setDrawerOptions } = useContext(LayoutContext)
    const [cardData, setCardData] = useState({calories: '', heartRate: '', sleep: '', acwr: '', acwrMileage: '', momentumScore: '', heatmap: [], startDate: '', endDate: '', trainerComments: '', wowLoad: '', wowDistance: ''});

    const [{ loading: listLoading }, info] = useApi('metrics/athlete_summary/', { manual: true })
    const [{ data: summary }] = useApi('home/summary/')
    const user = useContext(UserContext)
    const [showAcwrInfo, setShowAcwrInfo] = useState(false)
//    const [showRHRInfo, setShowRHRInfo] = useState(false)
//    const [showSleepInfo, setShowSleepInfo] = useState(false)
//    const [showCalBurnedInfo, setShowCalBurnedInfo] = useState(false)
//
//    const caloriesBurned = summary?.calories
//    const heartRate = summary?.heartRate
//    const sleepScore = summary?.sleep
    const acwr = summary?.acwr
    const acwrMileage = summary?.acwrMileage

    let acwrColor = 'black'
    if (acwr < 0.8 && acwr > 0) {
    acwrColor = '#FE9500'
    } else if (acwr >= 0.8 && acwr <= 1.3) {
    acwrColor = '#28a745'
    } else if (acwr > 1.3 && acwr < 1.5) {
    acwrColor = '#FE9500'
    } else if (acwr >= 1.5) {
    acwrColor = '#dc3545'
    } else {
    acwrColor = 'black'
    }

    let acwrMileageColor = 'black'
    if (acwrMileage < 0.8 && acwrMileage > 0) {
    acwrMileageColor = '#FE9500'
    } else if (acwrMileage >= 0.8 && acwrMileage <= 1.3) {
    acwrMileageColor = '#28a745'
    } else if (acwrMileage > 1.3 && acwrMileage < 1.5) {
    acwrMileageColor = '#FE9500'
    } else if (acwrMileage >= 1.5) {
    acwrMileageColor = '#dc3545'
    } else {
    acwrMileageColor = 'black'
    }

    const lastSynced = user?.fitbitLastSyncedAt
    ? moment(user.fitbitLastSyncedAt).fromNow()
    : 'No Data'

    const cal = new CalHeatmap()

    const fetchData = useCallback(
        async () => {
          const response = await info({})
          setCardData(response?.data)
          cal.paint({
            range: 2,
            domain: {type: 'month', gutter: 30},
            date: { start: moment().subtract(1, 'month').toDate(), max: new Date() },
            itemSelector: '#activity-heatmap',
            data: {source: response?.data?.heatmap, defaultValue: 0, x: 'date', y: 'value'},
            subDomain: {type: 'xDay', width: 20, height: 25, gutter: 4, label: 'D'},
            scale: {
                color: {
                  // Try some values: Purples, Blues, Turbo, Magma, etc ...
                    range: ['#EDEDED', '#0B545E'],
                    interpolate: 'hsl',
                  type: 'sqrt',
                  domain: [0, 30]
                },
            }
        });
        },
        [ info]
    )

    useEffect(() => {
      fetchData()
    }, [fetchData, info])

    useEffect(() => {
      setAppLoading(listLoading )
    }, [listLoading, setAppLoading])

    useAppTitle('My Report')

    useEffect(() => {
        setDrawerOptions({
            onAdd: null,
            showProgram: true,
            showAthletes: false,
        })
    }, [setDrawerOptions])

    const columns = [
        {
          name: 'Id',
          options: {
            display: 'excluded',
            filter: false,
            align: 'center',
          },
        },
        {
          name: 'Day',
          options: {
            customBodyRender: (value) => moment(value).format('L'),
            display: true,
            searchable: true,
            customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
          },
        },
        {
          name: 'Name',
          options: {
            display: true,
            filterType: 'multiselect',
            customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
          },
        },
        {
          name: 'Set Number',
          options: {
            display: true,
            customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
          },
        },
        {
          name: 'Weight (lbs)',
          options: {
            display: true,
            customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
          },
        },
        {
          name: 'Reps',
          options: {
            display: true,
            customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
          },
        },
        {
          name: 'Distance (meter)',
          options: {
            display: false,
            customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
          },
        },
        {
          name: 'Work Time (seconds)',
          options: {
            display: false,
            customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
          },
        },
        {
          name: 'Rest Time (seconds)',
          options: {
            display: false,
            customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
          },
        },
        {
          name: 'RIR',
          options: {
            display: false,
            customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
          },
        },
        {
          name: 'Calories Burned',
          options: {
            display: false,
            filter: false,
            customHeadRender: (columnMeta) => (
                <th key={columnMeta.name} style={{
                    color: 'white',
                    backgroundColor: '#0b545e',
                    textAlign: 'center',
                    fontWeight: '500'}}
                    >
                    {columnMeta.name}
                </th>
            )
          },
        },
    ]

    return (
        <Athletes.Stylesheet>

            <Grid container spacing={0} style={{height: '200px'}}>
            <Grid item xs={12}>
                <ResponsiveContainer width='100%' height={150}>
                  <PieChart>
                    <Pie startAngle={200} endAngle={-20} data={thresholds} dataKey="value" innerRadius="90%" outerRadius="100%" >
                      <Cell value={50} fill="#FF7262" />
                      <Cell value={20} fill="#FFF5E5" />
                      <Cell value={30} fill="#C7E8ED" />
                    </Pie>
                    <Pie startAngle={200} endAngle={-20} dataKey="value" innerRadius="65%" outerRadius="89%">
                      <Cell value={cardData?.momentumScore} fill="#0B545E" />
                      <Cell value={100-(cardData?.momentumScore ?? 0)} fill="white" />
                       <Label value={cardData?.momentumScore} position="center" style={{ textAnchor: 'middle', fontSize: '200%',}}/>
                    </Pie>
                  </PieChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item xs={12}>
            <PageTitle subtitle={`Last synced: ${lastSynced}`} />
                  <div className="section mt-2" style={{height: '100px'}}>
                    <div className="card">
                      <div className="card-header">
                        <span className="card-subtitle">Feedback from Coach</span>
                      </div>
                      <div className="card-body">
                          {cardData?.trainerComments || '-'}
                      </div>
                    </div>
                  </div>
            </Grid>
            <Grid item xs={12}>
                <div className="section mt-2">
                    <div className="card">
                      <div className="card-header">
                        <span className="card-subtitle">Acute to Chronic Ratio</span>
                         <IconButton
                          aria-label="Learn More"
                          onClick={() => setShowAcwrInfo(true)}
                        >
                          <InfoIcon />
                        </IconButton>
                      </div>

                      <div className="card-body" style={{display: 'flex'}}>
                          <h5 className="card-title" style={{width: '30%'}}>
                              Strength:
                          </h5>
                          <h5 style={{ color: acwrColor, width: '20%'}} className="card-title">
                            {acwr || '-'}
                          </h5>
                          <h5 className="card-title" style={{width: '30%'}}>
                            Distance:
                          </h5>
                          <h5 style={{ color: acwrMileageColor, width: '20%' }} className="card-title">
                            {acwrMileage || '-'}
                          </h5>
                      </div>
                    </div>
                  </div>
            </Grid>
            <Grid item xs={12}>
                <div className="section mt-2">
                    <div className="card">
                      <div className="card-header">
                        <span className="card-subtitle">Week Over Week Load Comparison Data</span>
                      </div>
                      <div className="card-body">
                      <h5 className="card-title">
                          Strength:     {cardData?.wowLoad || '-'} &nbsp; &nbsp; &nbsp;     Distance: {cardData?.wowDistance || '-'}
                      </h5>
                      </div>
                    </div>
                  </div>
            </Grid>
            <Grid item xs={12}>
                <div className="section mt-2" style={{height: '230px'}}>
                    <div className="card">
                      <div className="card-header">
                        <span className="card-subtitle">Active Days</span>
                      </div>
                      <div className="card-body" style={{display: 'flex', justifyContent: 'center'}}>
                        <div id="activity-heatmap"></div>
                      </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="section mt-2">
                    <div className="card">
                      <MUIDataTable
                        columns={columns}
                        data={cardData?.kpi}
                        options={{selectableRows: 'none', responsive: 'standard', search: 'false', filter: 'false',}}
                        title="KPIs"
                      />
                  </div>
                </div>
            </Grid>
            <Grid item xs={12}>
            <div className="section mt-2">
                <div className="card">
                  <div className="card-header">
                    <span className="card-subtitle">WORKLOAD</span>
                  </div>
                <div style={{ width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <LineChart
                      data={cardData?.acwrChartData}
                    >
                      <CartesianGrid />
                      <Tooltip />
                      <Line connectNulls type="monotone" dataKey="acwr_strength" stroke="#0B545E" />
                      <Line connectNulls type="monotone" dataKey="acwr_distance" stroke="#36454F" />
                      <XAxis dataKey="name" />
                      <YAxis />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="section mt-2">
                <div className="card">
                  <div className="card-header">
                    <span className="card-subtitle">CALORIES BURNED</span>
                  </div>
                <div style={{ width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={cardData?.calChartData}
                    >
                      <CartesianGrid />
                      <Tooltip />
                      <Bar dataKey="calories_burned" fill="#0B545E" />
                      <XAxis dataKey="name" />
                      <YAxis />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="section mt-2">
                <div className="card">
                  <div className="card-header">
                    <span className="card-subtitle">SLEEP</span>
                  </div>
                <div style={{ width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={cardData?.sleepChartData}
                    >
                      <CartesianGrid />
                      <Tooltip />
                      <Bar dataKey="sleep_hours" fill="#0B545E" />
                      <XAxis dataKey="name" />
                      <YAxis />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              </div>
            </Grid>
            <Grid item xs={12} style={{paddingBottom: '100px'}}>
              <div className="section mt-2">
                <div className="card">
                  <div className="card-header">
                    <span className="card-subtitle">HEART RATE</span>
                  </div>
                <div style={{ width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <LineChart
                      data={cardData?.hrChartData}
                    >
                      <CartesianGrid />
                      <Tooltip />
                      <Line connectNulls type="monotone" dataKey="heart_rate" stroke="#0B545E" />
                      <XAxis dataKey="name" />
                      <YAxis />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
              </div>
            </Grid>
            </Grid>
              <Dialog onClose={() => setShowAcwrInfo(false)} open={showAcwrInfo}>
                <DialogContent>
                  <DialogContentText>
                    <strong style={{ fontWeight: 'bolder' }}>
                      Acute-chronic workload ratio&nbsp;
                    </strong>
                    is a calculation of an athlete’s fitness and fatigue based on work
                    done (ex. weight lifted, distance ran). The acute workload (most
                    recent 7 days) is divided by the chronic workload (most recent 28
                    days) to present a ratio. The ideal ratio is 0.8-1.3 skewing to
                    either side increases fatigue and injury risk due to under/over
                    training. The acute-chronic workload can help with understanding the
                    preparedness of an athlete, their fatigue level and the relative
                    injury risk of an athlete day-to-day.
                  </DialogContentText>
                </DialogContent>
              </Dialog>

        </Athletes.Stylesheet>
    )
}

Athletes.Stylesheet = styled.div`


    .card-header {
    border-bottom: none;
    padding: 16px 16px;
  }

  .subtitle {
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  .section{
    padding: 0 4px;
    margin-top: 6px !important;
  }

  .MuiGrid-container {
    height: 200px;
  }

  .card-body{
    padding: 0px 12px;
  }

  .MuiIconButton-root{
    padding 3px;
  }

  .card{
    min-height: 100px;
  }

  footer{
    padding 10px 10px;
  }

  h2{
    display: inline;
  }

  .MuiTableCell-root{
    padding: 1px;
    text-align: center;
  }
  .MuiTableCell-head{
    background-color: #0b545e;
    color: white;
    text-align: center;
  }
  .MuiTableRow-root:nth-child(even){
    background-color: #dedede;
  }
  .MUIDataTableHeadCell-toolButton-55{
    color: white;
    text-align: center;
    margin: 0px 0px;
    width: 12vw;
  }
  .MUIDataTableToolbar-actions-17 {
    text-align: right;
  }
  .MuiButton-text {
    color: white;
  }

`

export default Athletes
